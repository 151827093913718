import React from "react";

// External

// Internal
import Layout from "../../components/layout";
import Seo from "../../components/SEO";
import ProjectsSection from "../../containers/projects-section";

const ProjectsPage = () => {
  return (
    <Layout>
      <Seo
        title="Projects"
        description="Freelance Projects by Parmeet Singh Asija"
      />
      <ProjectsSection />
    </Layout>
  );
};

export default ProjectsPage;
